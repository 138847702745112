import * as React from "react";
import { graphql } from "gatsby";
// import { HeroProfile } from "@components/Heros/HeroProfile";
import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { PersonCard } from "@components/PersonCard";
import { Person } from "../types/graphql";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { seoAtom, heroAtom } from "@/store";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";

type PersonTemplateProps = {
  data: {
    sanityPerson: Person;
  };
};

const PersonTemplate = ({ data }: PersonTemplateProps) => {
  useHydrateAtoms([
    [seoAtom, data.sanityPerson.seo], [heroAtom, undefined],
  ])
  return data?.sanityPerson ? (
    <>
      <Helmet>
        <title> {data.sanityPerson.seo.title} | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <BreadCrumbs
              paths={[
                { url: "/", label: "Home" },
                { url: "/about/", label: "About" },
                { url: "/about/ep3-team/", label: "EP3 Team" },
              ]}
              title={data.sanityPerson.name}
            />
            <Title>{data.sanityPerson.name}</Title>
          </Header>
          <PersonCard person={data.sanityPerson} />
        </Container>
      </Layout>
    </>
  ) : <>Something went wrong.</>;;
};

export const pageQuery = graphql`
  query ($slug: String!) {
    sanityPerson(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      seo {
        title
        description
      }
      image {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      _rawBio
      id
      lastName
      firstName
      organization
      role
    }
  }
`;
export default PersonTemplate;
